var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeModal),expression:"closeModal"}],staticClass:"new-time-picker-widget"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"},{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],ref:"input",class:[
      'new-time-picker-widget__input',
      _vm.value ? 'new-time-picker-widget__input_complete' : '',
      _vm.alt ? 'new-time-picker-widget__input_alt' : '',
      _vm.readonly && _vm.alt ? 'new-time-picker-widget__input_readonly' : ''
    ],attrs:{"type":"text","readonly":_vm.readonly,"placeholder":_vm.placeholder ? _vm.placeholder : 'ЧЧ:мм'},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.value=$event.target.value},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"trim",undefined,$event.key,undefined)){ return null; }return _vm.Change__Date__HoursMinutes($event)}],"click":_vm.switchList}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.showModal && !_vm.alt && _vm.readonly) || (_vm.showModal && !_vm.readonly && _vm.alt)),expression:"(showModal && !alt && readonly) || (showModal && !readonly && alt)"}],staticClass:"new-time-picker-widget__select",attrs:{"id":_vm.unique + 'time-list'}},_vm._l((_vm.timeArr),function(item,idx){return _c('div',{key:(item + "_" + idx),class:[
        'new-time-picker-widget__select__time',
        _vm.activeTime === idx ? 'new-time-picker-widget__select__time_active' : ''
      ],attrs:{"data-time":_vm.unique + item},on:{"click":function($event){return _vm.setTime(item, idx)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0),(!_vm.alt)?_c('i',{staticClass:"icon-down",class:_vm.showModal ? 'icon-down_active' : '',on:{"click":_vm.switchList}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }