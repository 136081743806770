<template>
  <div class="new-time-picker-widget" v-click-outside="closeModal">
    <input
      ref="input"
      @input.trim="Change__Date__HoursMinutes($event)"
      @click="switchList"
      v-model="value"
      type="text"
      :readonly="readonly"
      v-mask="'##:##'"
      :class="[
        'new-time-picker-widget__input',
        value ? 'new-time-picker-widget__input_complete' : '',
        alt ? 'new-time-picker-widget__input_alt' : '',
        readonly && alt ? 'new-time-picker-widget__input_readonly' : ''
      ]"
      :placeholder="placeholder ? placeholder : 'ЧЧ:мм'"
    />
    <div
      v-show="(showModal && !alt && readonly) || (showModal && !readonly && alt)"
      class="new-time-picker-widget__select"
      :id="unique + 'time-list'"
    >
      <div
        v-for="(item, idx) in timeArr"
        :key="`${item}_${idx}`"
        :class="[
          'new-time-picker-widget__select__time',
          activeTime === idx ? 'new-time-picker-widget__select__time_active' : ''
        ]"
        :data-time="unique + item"
        @click="setTime(item, idx)"
      >
        <span>{{ item }}</span>
      </div>
    </div>
    <i
      v-if="!alt"
      class="icon-down"
      @click="switchList"
      :class="showModal ? 'icon-down_active' : ''"
    ></i>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "NewTimePicker",
  mixins: [],
  props: {
    editValue: {
      type: String,
      default: ""
    },
    unique: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    alt: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    timeInterval: {
      type: Number,
      default: 60
    },
    maxCount: {
      type: Number,
      default: 24
    }
  },
  components: {},
  directives: {
    ClickOutside
  },
  data() {
    return {
      value: "",
      activeTime: null,
      showModal: false,
      timeArr: []
    };
  },
  beforeDestroy() {
    this.$root.$off(`time-new-child:change_${this.unique}`);
  },
  mounted() {
    this.timeArr = this.generateTimeArr(this.timeInterval);
    this.$root.$on(`time-new-child:change_${this.unique}`, (value) => {
      this.value = value;
    });
    if (this.editValue) {
      this.value = this.editValue;
      this.setActiveTime();
    }
  },
  methods: {
    switchList() {
      this.showModal = !this.showModal;
      this.scrollToSelectTime();
    },
    setActiveTime() {
      const index = this.timeArr.findIndex((item) => item === this.value.slice(0, 5));
      if (index > -1) {
        this.activeTime = index;
      } else {
        this.activeTime = null;
      }
    },
    scrollToSelectTime() {
      const [hh, mm] = this.value.split(":");
      let time = `${hh}:${mm}`;
      if (mm > 0 && mm < 30) {
        time = `${hh}:00`;
      } else if (mm > 30 && mm < 60) {
        time = `${hh}:30`;
      }
      const $el = document.querySelector(`[data-time="${this.unique}${time}"]`);
      if ($el) {
        this.$nextTick(() => {
          this.$scrollTo($el, 0, {
            container: `#${this.unique}time-list`,
            easing: "ease-in",
            lazy: true,
            offset: 0,
            force: true,
            cancelable: true,
            x: false,
            y: true
          });
        });
      }
    },
    Change__Date__HoursMinutes(event) {
      this.value = this.checkTimeValidation(event.target.value);
      this.scrollToSelectTime();
      this.setActiveTime();
      this.$root.$emit(`new-time:change_${this.unique}`, this.value);
    },
    clearInput() {
      this.value = "";
      this.$root.$emit(`new-time:change_${this.unique}`, this.value);
    },
    closeModal() {
      this.showModal = false;
    },
    generateTimeArr(interval) {
      let arr = [];
      let i = 0;
      let temp = 0;
      for (i; i <= this.maxCount; i++) {
        if (String(i).length === 1) {
          arr.push("0" + i + ":00");
          for (let k = 0; k < Math.floor(60 / interval); k++) {
            temp += interval;
            if (temp === 60) {
              break;
            }
            arr.push("0" + i + ":" + temp);
          }
          temp = 0;
          continue;
        }
        arr.push(i + ":00");
        for (let k = 0; k < Math.floor(60 / interval); k++) {
          temp += interval;
          if (temp === 60) {
            break;
          }
          arr.push(i + ":" + temp);
        }
        temp = 0;
      }
      return arr;
    },
    setTime(item, idx) {
      this.value = item;
      this.activeTime = idx;
      this.$refs.input.focus();
      this.$root.$emit(`new-time:change_${this.unique}`, this.value);
    },
    checkTimeValidation(time) {
      let temp = time.split(":");
      if (temp[0] >= 24) {
        temp[0] = "00";
      }
      if (temp[0].split("")[0] + temp[0].split("")[1] > 24) {
        temp[0] = "00";
      }
      if (temp[1] > 59) {
        temp[1] = 59;
      }
      temp = temp.join(":");
      return temp;
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    timeRangeMask(value) {
      const numbers = value.replace(/[^0-9]/g, "");
      if (numbers.length > 4) {
        return [
          ...this.timeMask(numbers.substring(0, 4)),
          "-",
          ...this.timeMask(numbers.substring(4))
        ];
      }
      return [...this.timeMask(numbers)];
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";
::-webkit-scrollbar {
  position: relative;
  height: 5px;
  overflow-x: auto;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 8px;

  background: $bg-gray-400;
}
::-webkit-scrollbar-track {
  background: $bg-surface-light;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.new-time-picker-widget {
  position: relative;
  &__input {
    display: flex;
    align-items: center;
    width: 90px;
    border: none;
    background-color: $system-grey-three;
    padding: 4px 4px 3px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    outline: none;
    transition: all 0.2s ease;
    border-bottom: 1px solid transparent;

    &_alt {
      background-color: transparent;
      color: $text-default;
      width: 100%;
      max-width: 55px;
      transition: all 0.3s ease;
      &:hover {
        background-color: $system-grey-three;
        border-bottom: 1px solid $bg-gray-400;
      }
      &:focus {
        background-color: $system-grey-three;
        border-bottom: 1px solid $action-primary;
      }
    }

    &::placeholder {
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $text-subdued;
    }

    &:focus {
      border-bottom: 1px solid $action-primary;
    }
    &_readonly {
      &:focus,
      &:hover {
        background: transparent;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .icon-down {
    font-size: 14px;
    position: absolute;
    top: 7px;
    right: 4px;
    color: $icon-subdued;
    transition: all 0.3s ease;
    &_active {
      transform: rotate(180deg);
    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 33px;
    left: 0;
    width: 90px;
    height: 268px;
    overflow-y: scroll;
    z-index: 5;
    padding: 8px;
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 10px rgba(0, 0, 0, 0.1);
    &__time {
      padding: 8px;
      cursor: pointer;

      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $text-default;
      border-radius: 4px;

      transition: all 0.2s ease;

      @media (hover: hover) {
        &:hover {
          background-color: $surface-primary-default;
        }
      }

      &:active {
        color: $text-default;
        background-color: $surface-primary-default;
      }

      &_active {
        color: $text-default;
        background-color: $surface-primary-default;
        position: relative;
        &:before {
          position: absolute;
          left: -8px;
          top: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          content: "";
          background-color: $action-primary;
          width: 3px;
          height: 36px;
        }
      }
    }
  }
}
</style>
